import { useState, useEffect } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import NavbarTainwind from '../Navbar/NavbarTailwind';
import AppLoader from '../Layout/Loader/AppLoader';

const Social = ({ isAuthenticated, user }) => {

  const shortVideos = [{
    id: 751276556,
  }, {
    id: 751289625,
  },
  ]

  const [isVideoLoading, setIsVideoLoading] = useState(true);

  const handleVideoLoaded = () => {
    console.log('video loaded')
    setIsVideoLoading(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setIsVideoLoading(false)
    }, 1000)
  }, [])

  return (
    isVideoLoading ? <AppLoader /> :
      <div>
        <NavbarTainwind isAuthenticated={isAuthenticated} user={user} />
        <div >
          {
            shortVideos.map((video, index) => {
              return (
                <div style={{ margin: 20 }}>
                  <Vimeo
                    video={video.id}
                    autoplay
                    controls={false}
                    responsive={true}
                    // onReady={handleVideoReady}
                    // onProgress={handleVideoProgress}
                    // onEnd={handleVideoEnd}
                    loop={false}
                    showTitle={false}
                    color="1F2937"
                  />
                </div>
              )
            })
          }
        </div>
      </div>
  )
}

export default Social;