import React from 'react'
import NavbarTainwind from '../../Navbar/NavbarTailwind'
import FAQ from '../FAQ/FAQ'
import Reviews from '../Reviews/Reviews'
import './Features.css'

const Features = ({ isAuthenticated, user }) => {
  return (
    <div>
      <NavbarTainwind isAuthenticated={isAuthenticated} user={user} />
      <FAQ />

      <div className="reviews-container-div">
        <Reviews />
      </div>
    </div>
  )
}

export default Features